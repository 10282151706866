/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import articles from "../data/articles.json";
import { GoShare } from "react-icons/go";
import { IoIosLink } from "react-icons/io";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import markdowns from "../data/markdowns";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
  FacebookIcon,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const Article = () => {
  const { articleId } = useParams();

  const markdown = markdowns[articleId];
  const navigate = useNavigate();

  const article = articles.find((e) => e.articleId === articleId) || {
    title: "",
    subtitle: "",
    headerImgUrl: "",
    articleId: "",
    date: "",
    author: "Yash Kumar Dromar",
    subjects: [],
    readTime: "0 min",
  };

  useEffect(() => {
    if (
      article.articleId == null ||
      article.articleId === undefined ||
      article.articleId === ""
    ) {
      navigate("/404");
    }
  }, []);

  return (
    <div className="lg:w-2/3 px-5 lg:px-10 py-5">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.subtitle} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.subtitle} />
        <meta
          property="og:url"
          content={`https://opinions.ykdromar.com/${articleId}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={article.headerImgUrl} />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.subtitle} />
        <meta name="twitter:image" content={article.headerImgUrl} />
        <meta name="author" content={article.author} />
        <meta name="keywords" content={article.subjects.toString} />
      </Helmet>
      <h1 className="text-3xl font-bold font-lora">{article.title}</h1>
      <h3 className="text-lg font-semibold mt-2 text-neutral-700 italic">
        {article.subtitle}
      </h3>
      <div className="flex justify-between items-center my-2 py-1 border-t border-b">
        <div className="flex justify-between flex-col">
          <h5 className="text-sm italic text-neutral-500 font-lora m-0">
            {article.author}
          </h5>
          <h5 className="text-sm italic text-neutral-500 font-lora">
            {article.date}&nbsp;.&nbsp;{article.readTime} read
          </h5>
        </div>
        <GoShare
          size={20}
          className="cursor-pointer"
          onClick={() => {
            const shareModal = document.getElementById("share_modal");
            if (shareModal) {
              shareModal.showModal();
            }
          }}
        />
        <dialog id="share_modal" className="modal">
          <div className="modal-box flex flex-col ">
            <div className="text-lg font-semibold">Share this article</div>
            <div className="w-full relative">
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full my-3 pr-10"
                readOnly
                defaultValue={`https://opinions.ykdromar.com/${articleId}`}
              />
              <IoIosLink
                size={20}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    `https://opinions.ykdromar.com/${articleId}`
                  );
                  toast.success("copied to clipboard");
                }}
                className=" absolute cursor-pointer rounded-full right-3 top-6 hover:text-slate-500 active:translate-y-1 ease-in-out focus:translate-y-1"
              />
            </div>
            <div className="w-full flex justify-evenly">
              <EmailShareButton
                url={`https://opinions.ykdromar.com/${articleId}`}
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <WhatsappShareButton
                url={`https://opinions.ykdromar.com/${articleId}`}
              >
                <WhatsappIcon size={40} />
              </WhatsappShareButton>
              <TwitterShareButton
                url={`https://opinions.ykdromar.com/${articleId}`}
              >
                <XIcon size={40} />
              </TwitterShareButton>
              <FacebookShareButton
                url={`https://opinions.ykdromar.com/${articleId}`}
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`https://opinions.ykdromar.com/${articleId}`}
              >
                <LinkedinIcon size={40} />
              </LinkedinShareButton>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
      </div>
      <figure className="h-full w-full">
        <img
          src={article.headerImgUrl}
          alt="headerImg"
          className="rounded-xl h-full w-full my-2"
        />
      </figure>
      <Markdown
        components={{
          iframe: ({ node, ...props }) => (
            <div className="w-full flex justify-center ">
              <iframe {...props} />
            </div>
          ),
          blockquote: ({ node, ...props }) => (
            <div className="w-full flex justify-center">
              <blockquote {...props} />
            </div>
          ),
          pre: ({ node, ...props }) => <pre className="my-3" {...props} />,
          img: ({ node, ...props }) => (
            <img className=" my-3 w-full rounded-md" {...props} />
          ),
          h1: ({ node, ...props }) => (
            <h1 className="text-3xl font-bold my-3" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="custom-2xl font-bold my-3" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="custom-xl font-bold my-3" {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 className="custom-lg font-bold my-3" {...props} />
          ),
          h5: ({ node, ...props }) => (
            <h5 className="custom-base font-bold my-3" {...props} />
          ),
          h6: ({ node, ...props }) => (
            <h6 className="custom-sm font-bold my-3" {...props} />
          ),
          p: ({ node, ...props }) => <p className="my-3" {...props} />,
          a: ({ node, ...props }) => <a className="custom-a" {...props} />,
        }}
        className="mt-3"
        rehypePlugins={[rehypeRaw]}
      >
        {markdown}
      </Markdown>
      <h4 className="text-lg font-semibold mt-10">Tags</h4>
      <div className="flex justify-start items-center flex-wrap mb-5 ">
        {article.subjects.map((e) => (
          <div className="bg-neutral-200 px-2 py-2 rounded-full m-2">{e}</div>
        ))}
      </div>
    </div>
  );
};

export default Article;
