import React from "react";
import articles from "../data/articles.json";
import ArticleCard from "./components/ArticleCard";
const Articles = () => {
  return (
    <div className="w-full lg:w-2/3  lg:px-10 py-5">
      <div className="flex flex-col justify-evenly items-center flex-wrap">
        {articles.map((articleData, index) => (
          <ArticleCard articleData={articleData} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Articles;
