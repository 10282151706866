import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ articleData }) => {
  return (
    <div className="card md:card-side rounded-none p-1 border-b m-2">
      <figure className="h-full">
        <img
          src={articleData.headerImgUrl}
          alt="headerImg"
          className="rounded-xl"
        />
      </figure>
      <div className="card-body p-1 sm:py-1 sm:px-5">
        <Link to={articleData.articleId} className="card-title font-lora ">
          {articleData.title}
        </Link>
        <p className="">{articleData.subtitle}</p>
        <div className="flex justify-between lg:flex-col">
          <h5 className="text-sm italic text-neutral-500 font-lora m-0">
            {articleData.author}
          </h5>
          <h5 className="text-sm italic text-neutral-500 font-lora m-0 flex justify-between flex-wrap">
            <span>{articleData.date}&nbsp;.&nbsp;</span>
            <span>{articleData.readTime} read</span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
