import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="w-full justify-evenly">
      <Link
        to="/"
        className="w-full flex flex-col items-center p-3 border-b text-neutral-800"
      >
        <h1 className="font-abril text-5xl">Opinions</h1>
        <h3 className="font-lora italic text-lg mt-1">
          Decoding Power, Politics & People
        </h3>
      </Link>
      {/* <nav className=" fixed top-0 right-0 p-1 flex flex-wrap w-32 items-center justify-evenly">
        <a
          href="https://www.linkedin.com/in/ykdromar/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin size={25} />
        </a>
        <a href="https://github.com/ykdromar" target="_blank" rel="noreferrer">
          <FaGithub size={25} />
        </a>
        <a href="mailto:ykdromarpro@gmail.com" target="_blank" rel="noreferrer">
          <MdEmail size={25} />
        </a>
      </nav> */}
      {/* <div className="navbar bg-base-100 border-b">
        <div className="navbar-start">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
            >
              <li>
                <a>About</a>
              </li>
              <li>
                <a>Contact</a>
              </li>
            </ul>
          </div>
          <div className="hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <a>About</a>
              </li>
              <li>
                <a>Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-end">
          <div className="form-control">
            <input
              type="text"
              placeholder="Search"
              className="input input-bordered w-24 md:w-auto"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
