import Article from "./article";
import Articles from "./articles";
import Footer from "./footer";
import Header from "./header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const App = () => {
  return (
    <div classNameName="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Articles />} />
          <Route path="/:articleId" element={<Article />} />
          <Route
            path="/404"
            element={<h1 className="text-center">Error 404: Page Not Found</h1>}
          ></Route>
          <Route
            path="*"
            element={<h1 className="text-center">Error 404: Page Not Found</h1>}
          ></Route>
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
};

export default App;
